// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    current_env: 'master',
    needl_url: 'https://master.test.needl.co',
    needl_front_end_url: 'https://app.master.test.needl.co',
    needl_back_end_url: 'https://backend.master.test.needl.co',
    needl_bo_url: 'https://bo.master.test.needl.co',
    googleApiKey: 'AIzaSyDg9JNdTfXzSDhxLQpDPtiPeWO8Hj2T-C0',
    gtmKey : 'GTM-MJN3J6N',
    cacheKey: '02c3620af664e997fe927770df90cadc7a7c1e41',
    salesIQKey: 'abee0380a54b5b6e4a5a5c855d40fc7542bd682d32621befaa9ae1da1dbe1032',
    appVersion: '02c3620af664e997fe927770df90cadc7a7c1e41'
};
